//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//



export default {

    props: {
        rowId:{
            type:String,
            default:'',
        }
    },
    components: {},
    data: function () {
        return {
            form: {},
        }
    },
    computed: {

    },
    watch: {


    },

    methods: {
        cancle: function (val) {
            this.$emit('close',val);
        },

        submit: function () {
            let that = this;
            if(!!this.rowId){
                this.$put(`${this.getGetters('canalInviteUrl')}/update`,this.form,function(data){
                    ShowMsg('编辑成功','success');
                    that.cancle('edit');
                },true)
            }else{
                this.$post(`${this.getGetters('canalInviteUrl')}/save`,this.form,function(data){
                    ShowMsg('添加成功','success');
                    that.cancle('submit');
                },true)
            }
        },

        getDetail:function(){
            let that = this;
            this.$get(`${this.getGetters('canalInviteUrl')}/detail`,{
                id:this.rowId
            },function(data){
                this.form = data;
                Object.keys(data).forEach(function(key){
                    that.$set(this.form, key, data[key]);
                });
            })
        },

        areaCodeChange(val){
            this.$refs.myform.$refs.form.validateField('areaCode');
        }

    },
    mounted () {
        if(!!this.rowId){
            this.getDetail();
        }
    }
}
