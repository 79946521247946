//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LIST_MIXIN from '@mixins/list-page'
import COMMON_METHODS from '@mixins/common-methods.js'
import addCanalInvite from './addCanalInvite'

export default {
    mixins: [LIST_MIXIN,COMMON_METHODS],
    props:[],
    components:{
        addCanalInvite,
    },
    data: function(){
        return{
            pgData:{

            },
            tableData:[],

            dialogTitle:'',
            canalInviteDialogStatus:false,

            row:{},

            regStatusList:[
                {value:'未注册',key:0,type:'info'},
                {value:'已注册',key:1,type:'success'},
            ],

            smsStatusList:[
                {value:'未发送',key:0,type:'info'},
                {value:'已发送',key:1,type:'success'},
                {value:'发送失败',key:2,type:'danger'},
            ],
        }
    }, 
    computed:{
 
    },
    watch:{

    },

    methods: {
        doSearch:function(){
            this.$refs.page.queryData();
        },

        reload:function(){
            this.$refs.page.reload();
        },

        addHandler(){
            this.row = {};
            this.dialogTitle = '添加邀请';
            this.canalInviteDialogStatus = true;
        },

        canalInviteDialogClose(val){
            this.canalInviteDialogStatus = false;
            if(val === 'submit'){
                this.doSearch();
            }

            if(val === 'edit'){
                this.reload();
            }
        },

        editHandler(row){
            this.row = row;
            this.dialogTitle = '编辑邀请';
            this.canalInviteDialogStatus = true;
        },

        sendMsgHandler(row){
            let that = this;
            ShowConfirm(`请确认是否对 ${row.canalName}(${row.canalMobile}) 发送短信?`, 'warning', () => {
                that.$post(`${that.getGetters('canalInviteUrl')}/sendSms`,{
                    id:row.id
                },function(data){
                    ShowMsg('操作成功','success');
                    that.reload();
                })
            });
        }
    },
    mounted() {

    }
}
